.home-wrapper {
    height: 100vh !important;
    overflow-x: hidden;
}

/* .home-content {
    padding: 1.5rem 1.0rem 1.5rem 11rem !important;
} */

.home-content {
   min-height: 100vh;

   padding: 1.5rem 1rem 1.5rem 2.844rem !important;
   background-color: rgb(255, 255, 255) !important;
   border-top-left-radius: 25px !important;
   border-bottom-left-radius: 25px !important;
   /* box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.16); */
   box-shadow: -4px 3px 10px 0 rgba(0, 0, 0, 0.16);
}

