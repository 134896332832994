.drawer {
    position: fixed;
    display: none;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #ffffff;
    z-index: 1000;
    overflow-y: auto;
    /* visibility: hidden;
    opacity: 0; */
    width: 0;

    -webkit-box-shadow: -5px 0px 5px -5px rgba(0, 0, 0, 0.55);
    -moz-box-shadow: -5px 0px 5px -5px rgba(0, 0, 0, 0.55);
    box-shadow: -5px 0px 5px -5px rgba(0, 0, 0, 0.55);
    /* animate.css */
    -webkit-animation-duration: 1s; /* Chrome, Safari, Opera */
    animation-duration: 1s;
}

.drawer.show {
    /* visibility: visible;
    opacity: 1; */
    width: 100%;
    display: block;
}
